.protection {
  .protection-inside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 23rem 2rem 23rem;
    padding: 4.5rem 0 12.6rem 0;
    .userprotection-heading {
      margin-bottom: 2rem;
    }
    .userprotection-sub_heading {
      margin-bottom: 3rem;
    }
    .userProtection-disc {
      margin-bottom: 3rem;
    }
    .userProtection-sub_headings {
      margin-bottom: 2rem;
    }
    .userProtection-points {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .sub-heading {
      color: #f5f9fc;
      font-weight: 500;
      font-size: 26px;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .protection-description {
      color: #f5f9fc;
      font-weight: 300;
      font-size: 26px;
    }
    .content-heading {
      color: #8b6cd8;
      font-weight: 800;
      font-size: 35px;
    }
  }
}

@media (max-width: 600px) {
  .protection {
    .protection-inside {
      margin: 0 2rem 0rem 2rem;
      padding: 4.5rem 0 4.2rem 0;
    }
  }
}
