.ecosystem {
  .ecosystem-inside {
    justify-content: space-between;
    margin: 0 23rem 0 23rem;
    padding: 4.5rem 0 12.6rem 0;

    button {
      margin: 0 auto;
      margin-top: 5.5rem;
      margin-bottom: 2rem;
      background-color: #3696d2;
      width: 31.1rem;
      height: 6.3rem;
      border-radius: 2.9rem;
      border-width: 0;
      font-size: 3rem;
      font-weight: 500;
      color: #ffffff;
      display: block;
    }
    .ecosystem-heading1 {
      margin-bottom: 2.5rem;
    }
    .ecosystem-heading2 {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 600px) {
  .ecosystem {
    .ecosystem-inside {
      justify-content: space-between;
      margin: 0 2rem 0 2rem;
      padding: 6rem 0 6rem 0;

      button {
        margin-top: 3rem;
        width: 16.4rem;
        height: 3.85rem;
        border-radius: 6.9rem;
        font-size: 1.8rem;
      }
      .ecosystem-heading1 {
        margin-bottom: 2.5rem;
      }
      .ecosystem-heading2 {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
