
.contactus-up {
    width: 100%;
    padding-bottom: 8rem;
    margin-left: 17rem;
}


img.contactus-bg {
    position: absolute;
    left: 50%;
    opacity: 30%;
    transform: translateX(-50%);
    width: 25rem;
}

.contactus-inside {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-color: white;
    border-radius: 1.5rem;
}

.contactus-content {
    padding-right: 5rem;
}

.contactus-content>h2 {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 4.5rem;
    line-height: 5rem;
    margin-bottom: 3rem;
}

.contactus-points {
    margin-left: 4rem;
    font-size: 2rem;
    line-height: 3rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 400;
}

.contactus-input {
    box-shadow: 0 -0.313529rem 0.470293rem rgba(188, 188, 188, 0.25),
        0 6.31761rem 8.28511rem rgba(51, 51, 51, 0.1);
    border-radius: 1.5rem;
    width: 44.5rem;
    background-color: white;
    position: relative;
    align-items: center;
    transform: translateY(-5rem);
}

.contactus-input-in {
    padding: 3rem;
}

.text {
    height: 4rem;
    width: 90%;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding-left: 2rem;
    border: 0.117573rem solid #666666;
    border-width: 0.15rem;
    text-align: left;
    margin-bottom: 1.5rem;
}

.name {
    border: 0.15rem solid #0A142F;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #0A142F;
}

.name::placeholder {
    color: #0A142F;
}

.message {
    width: 90%;
    height: 15.5rem;
    border: 0.11rem solid #666666;
    border-radius: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #0A142F;
    padding-left: 2rem;
    padding-top: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
    resize: none;
}

.contactus-submit {
    margin-top: 3.2rem;
    background: #3696D2;
    box-shadow: 0 1.88117rem 2.665rem rgba(32, 70, 100, 0.1);
    border-radius: 0.783822rem;
    border-width: 0;
    width: 12.2rem;
    height: 4rem;
    font-weight: 600;
    font-size: 1.28rem;
    line-height: 2.8rem;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    }

    .error {
        border-color: tomato;
        border-width: 0.3rem;
        color: red;
    }

    .error::placeholder {
        color: red;
    }

    .contactus-spinner {
        height: 3rem;
        width: 3rem;
    }


@media (max-width:600px) {

    .contactus-bg {
        display: none;
    }

    .contactus-up {

        margin-left: 0;
    }

    .contactus {
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: auto;
        width: auto;
    }

    .contactus-inside {
        flex-direction: column;
    }
    
    .contactus-content {
        margin-top: 2.8rem;
        height: 17.13rem;
        box-shadow: 0px 3.13529px 20px rgba(51, 51, 51, 0.1);
        border-radius: 1.5rem;
        margin-bottom: 2.8rem;
        width: 90%;
        padding-right: 0;
    }
    
    .contactus-content>h2 {
        font-size: 2rem;
        padding-left: 2.8rem;
        margin-bottom: 0;
    }

    .contactus-points {
        width: auto;
        padding-left: 4rem;
        margin-left: 0rem;
    }

    .contactus-point {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 2.8rem;
    }

    .contactus-input {
        box-shadow: 0px 3.14px 20px rgba(51, 51, 51, 0.1);
        border-radius: 1.5rem;
        width: auto;
        transform: none;
        margin-bottom: 5rem;
    }

    .contactus-input-in {
        padding: 2.2rem;
    }

    .text {
        height: 4.38rem;
        width: 94%;
        border-radius: 0;
        font-size: 1.4rem;
        padding-left: 1.4rem;
        margin-bottom: 1.5rem;
        border: 0.05rem solid #E5E5E5;
        border-radius: 0.89rem;
    }

    .name {
        border: 0.05rem solid #0A142F;
    }

    .message {
        width: 92.7%;
        height: 14.55rem;
        border: 0.562886px solid #E5E5E5;
        border-radius: 0.89rem;
    }

    .contactus-submit {
        width: 17.7rem;
        height: 4.4rem;
        border-radius: 0.89rem;
        font-size: 1.4rem;
        font-weight: 600;
    }
}