.aboutUs {
  .aboutUs-description {
    margin: 5rem 20rem ;
    transform: translateY(-20rem);
    button {
      margin-top: 6rem;
      background-color: #3696d2;
      width: 20.1rem;
      height: 4.7rem;
      border-radius: 2.9rem;
      color: #fff;
      font-size: 2.2rem;
      border-width: 0;
    }
  }
}

@media (max-width: 600px) {
  .aboutUs {
    .aboutUs-description {
      margin: 5rem 3rem;
      margin-bottom: 0;
      padding-bottom: 5rem;
      transform: translateY(0rem);

      button {
        display: block;
        margin: 0 auto;
        width: 14rem;
        height: 3rem;
        font-size: 1.3rem;
      }
    }
  }
}
