.whiteComponent {
    width:100%;
    min-height:100vh;
    display:flex;
    justify-content:center;

    .whiteComponent-inside {
        width:140.2rem;
        margin: auto auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    

        .whiteComponent-text {
            width: 90rem;
            height: auto;
            display: flex;
            margin: auto 0;
            flex-direction: column;

            h1 {
                font-weight: bold;
                margin-bottom: 3.7rem;
            }

            p {
                font-weight: 300;
                font-size:3rem;
            }
        }
       
    }
}

@media (max-width: 600px) {
    .whiteComponent {
    min-height:auto;
    
        .whiteComponent-inside {
            width: auto;
            margin: 3rem;
    
            .whiteComponent-text {
                width: 100%;
                height: auto;

                h1 {
                    margin-bottom: 3rem;
                }
    
                p {
                    width: auto;
                    height: auto;
                    font-size:1.6rem;
                }

                .points {
                    margin-top: 4.5rem;
                }
            }
        }
    }
}

.screen-animate-whiteComponent {
animation: screen_animate_whiteComponent 1s;
}

@keyframes screen_animate_whiteComponent {
    0% {
        opacity: 0;
        transform: translateX(-150%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}