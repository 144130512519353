.userBenefits {
  .userBenefits-inside {
    justify-content: space-between;
    margin: 0 23rem 0 23rem;
    padding: 5.5rem 0 12.6rem 0;
  }
  .userBenefits-heading {
    margin-bottom: 2rem;
  }
  .userBenefits-discription {
    font-weight: 500;
    font-size: 2.6rem;
  }
  .userBenifits-subHeading {
    margin-top: 2rem;
  }
  .userBenefits-subheading1 {
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .userBenefits {
    .userBenefits-inside {
      margin: 0 2rem 0 2rem;
      padding: 5.5rem 0 5.6rem 0;
    }
    .userBenefits-heading {
      margin-bottom: 1rem;
    }
    .userBenefits-discription {
      font-size: 1.6rem;
    }
    .userBenifits-subHeading {
      margin-top: 2rem;
    }
    .userBenefits-subheading1 {
      margin-bottom: 2rem;
    }
  }
}
