html {
    padding: 0px;
    margin: 0px;
    width: 100%;
    overflow-x: hidden;
    font-size: 50%;
    /* background-color: black; */
}

* {
    margin: 0;
    padding: 0;
    font-family: Poppins;
}

.screen {
    width: 32.75rem;
    height: 67.37rem;
    margin: auto;

}

.common-h1 {
    color: #FFFFFF;
    font-size: 4.5rem;
   // font-weight: bold;
}

.common-p {
    color: #FFFFFF;
    font-size: 2.6rem;
    font-weight: 300;
}

.canvas {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.phone {
    display: none;
}

.desktop {
    display: block;
}

a {
    text-decoration: none;
}

.hide {
    opacity: 0;
}
button:hover{
      cursor:pointer;
  }

  button {
          margin-top: 6rem;
          background-color: #3696d2;
          width: 20.1rem;
          height: 4.7rem;
          border-radius: 2.9rem;
          color: #fff;
          font-size: 2.2rem;
          border-width: 0;
        }

@media (max-width: 600px) {
    .screen {
        width: 11rem;
        height: 21.8rem;
    }

    
    .common-h1 {
        font-size: 2.7rem;
        text-align: center;
    }

    .common-p {
        font-size: 2.4rem;
    }

    .desktop {
        display: none;
    }
    
    .phone {
        display: block;
        margin: 0 auto;
    }

    button {
              margin: 0 auto;
              margin-top: 2rem;
              width: 21rem;
              height: 3.7rem;
              border-radius: 3.6rem;
              font-size: 1.5rem;
            }
   
}