.landingPageCommon {
  min-height: 100vh;
  .landingPageCommon-landing-container {
    height: 100vh;
    display: flex;
    .landingPageCommon-inside {
      display: flex;
      justify-content: space-between;
      margin: auto 8rem;
      margin-top: 4rem;

      .landingPageCommon-content {
        margin-top: 18rem;
        margin-left: 10rem;

        h1 {
          width: 85rem;
          text-align: start;
        }

        p {
          margin-top: 1.5rem;
          width: 72.3rem;
          text-align: start;
        }
      }
      .goCornarButton {
        margin-top: 8rem;
        background-color: #fff;
        width: 20.1rem;
        height: 5.7rem;
        border-radius: 2.9rem;
        color: #FFB900;
        font-size: 2.2rem;
        border-width: 0;
      }

      .landingPageCommon-image {
        margin:5rem;
        position: relative;

        .screen-animate-landingPageCommon {
          animation: screen-animate-landingPageCommon 1s;
          position: relative;
          left: 50%;
        }
        @keyframes screen-animate-landingPageCommon {
          0% {
            transform: translate(-0%, 30%);
          }

          100% {
            transform: translate(-0%, -0%);
          }
        }
      }
    }
  }
}

.screen-animate-landingPageCommon-screenRight {
  animation: screen-animate-landingPageCommon-screenRight 1s;
  position: absolute;
  left: 111%;
  width: 30.2rem;
  height: 62.2rem;
  top: 50%;
  transform: translate(0%, -50%);
}

@keyframes screen-animate-landingPageCommon-screenRight {
  0% {
    transform: translate(-50%, -10%);
  }

  100% {
    transform: translate(-0%, -50%);
  }
}

.screen-animate-landingPageCommon-screenLeft {
  animation: screen-animate-landingPageCommon-screenLeft 1s;
  position: absolute;
  width: 30.2rem;
  height: 62.2rem;
  top: 50%;
  transform: translate(0, -50%);
}
@keyframes screen-animate-landingPageCommon-screenLeft {
  0% {
    transform: translate(50%, -10%);
  }

  100% {
    transform: translate(0%, -50%);
  }
}

@media (max-width: 600px) {
  .landingPageCommon {
    min-height: auto;
    .landingPageCommon-landing-container {
      height: auto;
      display: block;
      transform: translateY(0);
      .landingPageCommon-inside {
        display: block;
        margin: 2rem;
        padding: 2.9rem 0 3rem 0;

        .landingPageCommon-content {
          margin-top: 1rem;
          margin-left: 0;

          h1 {
            text-align: center;
            width: auto;
            margin-bottom: 3rem;
          }

          p {
            margin-top: 2rem;
            width: auto;
            margin-bottom: 2rem;
            text-align: center;
            height: 9.5rem;
          }

          button {
            display: block;
            margin: 3rem auto 5rem auto;
            width: 14rem;
            height: 4rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  
  
  .screen-animate-landingPageCommon-screenRight {
    animation: screen-animate-landingPageCommon-screenRight 1s;
    left: 40%;
    width: 9.6rem;
    height: 19.2rem;
    transform: translate(85%, -87%);
  }

  @keyframes screen-animate-landingPageCommon-screenRight {
    0% {
    transform: translate(35%, -30%);
    }

    100% {
    transform: translate(85%, -87%);
    }
  }

  .screen-animate-landingPageCommon-screenLeft {
    animation: screen-animate-landingPageCommon-screenLeft 1s;
    width: 9.6rem;
    top: 50%;
    height: 19.2rem;
    transform: translate(135%, -87%);
  }
  @keyframes screen-animate-landingPageCommon-screenLeft {
    0% {
    transform: translate(185%, -30%);
    }

    100% {
    transform: translate(135%, -87%);
    }
  }

  .screen_animate_landingPageCommon {
    animation: screen_animate_landingPageCommon 1s;
    z-index: 100;
    position: relative;
  }
  @keyframes screen_animate_landingPageCommon {
    0% {
      transform: translate(-0%, 50%);
    }

    100% {
      transform: translate(-0%, 0%);
    }
  }
}
