.messaging {
  .messaging-inside {
    padding: 6.8rem 0 4.8rem 0;
    margin: 0 13.6rem 0 16.8rem;
    text-align: center;

    h1 {
      margin-bottom: 1.23rem;
    }

    h4 {
      font-weight: 500;
      font-size: 2.6rem;
    }

    .messaging-container {
      display: flex;
      justify-content: space-between;
      margin-top: 4.1rem;

      .messaging-content {
        width: 42.8rem;

        .icon {
          margin: 4.2rem auto 2.4rem auto;
          width: 11.2rem;
          height: 10.613rem;
          border-radius: 50%;
        }

        h3 {
          font-weight: bold;
          font-size: 2.6rem;
        }

        p {
          margin-top: 2rem;
          font-weight: 300;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .messaging {
    .messaging-inside {
      padding: 6rem 0 4.8rem 0;
      margin: 0 1.6rem 0 1.6rem;
      text-align: center;

      h1 {
        margin-bottom: 0.8rem;
      }

      h4 {
        font-size: 2rem;
      }

      .messaging-container {
        display: flex;
        justify-content: space-between;
        margin-top: 3.2rem;

        .messaging-content {
          width: 15rem;

          .icon {
            margin: 2rem auto 0.8rem auto;
            width: 4rem;
            height: 4rem;
          }

          h3 {
            font-weight: bold;
            font-size: 1rem;
          }

          p {
            margin-top: 1rem;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

.screen-animate-messaging {
  animation: screen_animate_messaging 1s;
  }
  
  @keyframes screen_animate_messaging {
      0% {
          opacity: 0;
          transform: translateX(-150%);
      }
  
      100% {
          opacity: 1;
          transform: translateX(0%);
      }
  }

    .text-anime{
      animation: text-anime 2s ease-out;

}
      @keyframes text-anime {
          0% {
              opacity: 0;
          }
          50% {
              opacity: 0;
          }
          100% {
              opacity: 1;
          }
      }