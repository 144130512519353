.random-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
  }
  
  .random-image {
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  

  
  .size1 {
    width: 60px;
    height: 60px;
  }
  .size2 {
    width: 30px;
    height: 30px;
  }
  .size3 {
    width: 100px;
    height: 100px;
  }
  .size4 {
    width: 125px;
    height: 125px;
  }
  .size5 {
    width: 175px;
    height: 150px;
  }

  .position1 {
    top: 10%;
    left: 10%;
  }
  
  .position2 {
    top: 5%;
    left: 40%;
  }
  
  .position3 {
    top: 10%;
    left: 70%;
  }
  .position4 {
    top: 40%;
    left: 0%;
  }
  
  .position5 {
    top: 30%;
    left: 25%;
  }
  
  .position6 {
    top: 40%;
    left: 65%;
  }
  .position7 {
    top: 60%;
    left: 65%;
  }
  
  .position8 {
    top: 90%;
    left: 40%;
  }
  
  .position9 {
    top: 60%;
    left: 80%;
  }
  .position10 {
    top: 80%;
    left: 60%;
  }
  
  
  .position12 {
    top: 90%;
    left: 0%;
  }
  
  