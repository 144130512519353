.features{
    display: flex;
    justify-content:center;

    .features-inside {
        width: 155rem;
        display: flex;
        justify-content: space-between;
        margin: 14rem 0 20rem 0;

        .feature {
            position: relative;
            h3 {
                font-style: normal;
                position: absolute;
                left:15rem;
                // top:0;
                bottom: -10rem;
                font-weight: bold;
                font-size: 3.2rem;
                line-height: 4.5rem;
                letter-spacing: 0.01rem;
                margin-top: 8.3rem;
                text-align: center;
            }

            p {
                width: 45rem;
                text-align: center;
                // margin-left: 5.7rem;
                font-style: normal;
                font-weight: normal;
                letter-spacing: 0.02rem;
                color: black;
                min-height: 12rem;

            }
          
               
            button {
                margin: 0 auto;
                margin-top: 3.5rem;
                background-color: #3696d2;
                width: 20.1rem;
                height: 4.3rem;
                border-radius: 2.9rem;
                border-width: 0;
                font-size: 2rem;
                font-weight: 500;
                color: #ffffff;
                display: block;
              }       
        }
    }
}

h3.feature-one {
    color: #F8281C;
}

h3.feature-two {
    color: #FF8400;    
}

h3.feature-three {
    color: #5CA401;
}

@media (max-width: 800px){

    .features{

        .features-inside {
           flex-wrap: wrap;
           .feature{
            width: 100%;

           }
        }
    }

}

@media (max-width: 600px) {
    .features{
        justify-content: start;
    
        .features-inside {
            width: 100%;
            margin: 5rem 3rem 7rem 3rem;
    
            .feature {

                    width: 10rem;    
                h3 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    letter-spacing: 0.01rem;
                    margin-top: 2.3rem ;
                    text-align: center;
                    min-height: 4.5rem;
                }
    
                p {
                    font-style: normal;
                    font-weight: normal;
                    letter-spacing: 0.02rem;
                    color: black;
                    font-size: 0.9rem;
                    width: auto;
                    min-height: 8rem;
                }
               
                button {
                    margin: 0 auto;    
                    margin-top: 2.5rem;    
                    background-color: #3696d2;   
                    width: 9rem;    
                    height: 2.3rem;  
                    border-radius: 2.9rem;   
                    border-width: 0;       
                    font-size: 1rem;
                    font-weight: 500;
                    color: #ffffff;
                    display: block;
                  }
                
            }
           
            }
        }
    }

 

        .screen-animate-features {
            animation: screen_animate_features1 1s;
            }
            
            @keyframes screen_animate_features1 {
                0% {
                    opacity: 0;
                    transform: translateX(-150%);
                }
            
                100% {
                    opacity: 1;
                    transform: translateX(0%);
                }
            }
.text-anime{
            animation: text-anime 2s ease-out;

}
            @keyframes text-anime {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }