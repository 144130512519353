.accounts {
    height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    .accounts-inside {
        display: flex;
        justify-content: space-between;
        margin: 0 14rem;

        .accounts-content {
            width: 85rem;
            height: 61rem;

            .accounts-icons {
                margin-top:3.5rem;
                display: flex;
                justify-content: space-between;
                div {
                    display: flex;
                    flex-direction: column;

                    p {
                        margin: 3rem auto 0 auto;
                        margin-top:3rem;
                    }

                    div {
                        width: 9.6rem;
                        height: 9.6rem;
                        border-radius: 49%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .accounts-public {
                    background-color: #D878FF;
                    img {
                        width: 5.1rem;
                        height: 5.4rem;
                    }
                }

                .accounts-family {
                    background-color: #8B6CD8;
                    img {
                        width: 5rem;
                        height: 5rem;
                    }
                }

                .accounts-friends {
                    background-color: #FFA621;
                    img {
                        width: 5rem;
                        height: 4.2rem;
                    }
                }

                .accounts-work {
                    background-color: #1F89CA;
                    img {
                        width: 6.62rem;
                        height: 4.55rem;
                    }
                }
            }

            p {
                margin-top: 6rem;
                font-weight: 500;
                margin: 2rem 0 2rem 0.4rem;

            }

            .accounts-discription {
                font-weight: 600;
                height:auto;
                background: #E9EAF8;
                font-size: 2rem;
                width: 74.6rem;
                padding: 4rem;
                margin: 0 auto;
                margin-top: 6rem;
                border-radius: 1.7rem;
                color: #D878FF;
            }
        }
    }
}


@media (max-width: 600px) {
    .accounts {
        height: auto;
        .accounts-inside {
            margin: 3rem;
            padding-bottom: 2rem;
    
            .accounts-content {
                width: auto;
                margin-top: 0;

                h1 {
                    margin-bottom: 3rem;
                }
    
                .accounts-icons {
                    margin-top:1.5rem;
                    div {
                        display: flex;
                        flex-direction: column;
    
                        p {
                            margin: 1.5rem auto 3rem auto;
                        }

                        div {
                            width: 7.3rem;
                            height: 7.3rem;
                            border-radius: 50%;
                        }
                    }
    
                    .accounts-public {
                        img {
                            width: 3rem;
                            height: 3.2rem;
                        }
                    }
    
                    .accounts-family {
                        img {
                            width: 3rem;
                            height: 3rem;
                        }
                    }
    
                    .accounts-friends {
                        img {
                            width: 3rem;
                            height: 3.1rem;
                        }
                    }
    
                    .accounts-work {
                        img {
                            width: 4.3rem;
                            height: 3.2rem;
                        }
                    }
                }
    
                p {
                    margin-top:2.5rem;
                    font-weight: 500;
                }
    
                .accounts-discription {
                    font-size: 1rem;
                    width: auto;
                    padding: 1.5rem;
                    margin: 0 auto 3rem auto;
                }
            }
        }
    }
}

.screen-animate-accounts {
    animation: screen_animate_accounts 1s;
    }
    
    @keyframes screen_animate_accounts {
        0% {
            opacity: 0;
            transform: translateX(-150%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }